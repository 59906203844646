import React from 'react'

// Components
import { Link } from 'react-router-dom'
import { TextCurve } from 'components/text-curve'
import { Imgry } from 'components/imgry'

// Style
import s from './index.module.scss'

export const ContributorList = ({ contributors = [], noBreak = false }) => {
  return (
    <ul className={`${s['display']} ${noBreak ? s['no-break'] : ''}`}>
      {contributors.map(({ name, image, slug }, index) => (
        <React.Fragment key={slug}>
          {index > 0 && <br />}
          <li className={s['contributor']}>
            <Link to={`/contributors/${slug}`}>
              <Imgry
                src={`${image}?w=300`}
                width={300}
                height={300}
                alt={`Watercolor Portrait of ${name}`}
                className={s['portrait']}
              />
              <TextCurve styleSheet={s} className={s['name']}>
                {name}
              </TextCurve>
            </Link>
          </li>
        </React.Fragment>
      ))}
      <li className={s['contributor']} />
    </ul>
  )
}
