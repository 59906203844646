import React from 'react'

// Style
import s from './index.module.scss'

export const StaggerList = ({ elements = [], noBreak = false }) => {
  return (
    <ul className={`${s['display']} ${noBreak ? s['no-break'] : ''}`}>
      {elements.map((element, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />}
          <li className={s['element']}>
            {element}
          </li>
        </React.Fragment>
      ))}
      <span className={s['element']} />
    </ul>
  )
}
