import React from 'react'
import PropTypes from 'prop-types'
import { ScrollContext } from './scroll-context'

const propTypes = {
  children: PropTypes.func.isRequired
}

class ScrollTracker extends React.Component {
  constructor() {
    super()

    this.state = {}

    // Bind functions

    this.trackScrollPosition = this.trackScrollPosition.bind(this)
    this.setScrollElement = this.setScrollElement.bind(this)
  }

  setElementPosition(element) {
    const {
      scrollTop,
      scrollLeft,
      clientHeight,
      clientWidth,
      clientTop,
      clientLeft,
      scrollHeight,
      scrollWidth
    } = element

    this.setState({
      scrollPosition: {
        scrollTop,
        scrollLeft,
        clientHeight,
        clientWidth,
        clientTop,
        clientLeft,
        scrollHeight,
        scrollWidth
      }
    })
  }

  setScrollElement(element) {
    if (element === null) return
    this.setElementPosition(element)
  }

  trackScrollPosition(e) {
    this.setElementPosition(e.target)
  }

  render() {
    const { children } = this.props
    const { scrollPosition } = this.state
    return (
      <ScrollContext.Provider value={{ scrollPosition }}>
        {children({
          trackScrollPosition: this.trackScrollPosition,
          setScrollElement: this.setScrollElement
        })}
      </ScrollContext.Provider>
    )
  }
}

ScrollTracker.propTypes = propTypes

export { ScrollTracker }
