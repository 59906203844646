import React from 'react'
import PropTypes from 'prop-types'
import { domUtils } from 'utils/dom-utils'

TextCurve.propTypes = {
  children: PropTypes.string.isRequired,
  styleSheet: PropTypes.object,
  id: PropTypes.string
}

export function TextCurve({ children, styleSheet = {}, id = '', ...rest }) {
  const connector = id ? '-' : ''
  const pathId = `${id}${connector}${domUtils.createElementId()}`
  return (
    <svg viewBox="0 0 100 52" {...rest}>
      <path
        id={pathId}
        d="M0,0c0,27.6,22.4,50,50,50s50-22.4,50-50"
        style={{ fill: 'transparent' }}
      />
      <text width="100" className={styleSheet['text']}>
        <textPath
          xlinkHref={`#${pathId}`}
          textAnchor="middle"
          startOffset="50%"
          className={styleSheet['text-path']}
        >
          {children}
        </textPath>
      </text>
    </svg>
  )
}
