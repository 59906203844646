import React from 'react'
import { AnalyticsConnectedComponent } from './analytics-connected-component'

export function withLocationCallback(callback, Component) {
  return React.forwardRef(function ComponentWithLocationCall(props, ref) {
    return (
      <AnalyticsConnectedComponent callback={callback}>
        <Component {...props} ref={ref} />
      </AnalyticsConnectedComponent>
    )
  })
}
