import React from 'react'

// Components
import { ReactComponent as Twitter } from 'assets/twitter.svg'
import { ReactComponent as Facebook } from 'assets/facebook.svg'
import { ReactComponent as Instagram } from 'assets/instagram.svg'

// Style
import s from './index.module.scss'

export const SocialList = ({
  facebook,
  instagram,
  twitter,
  className = ''
}) => (
  <div className={`${s['social-list']} ${className}`}>
    {facebook !== undefined && (
      <FacebookLink url={facebook} className={s['social-link']} />
    )}
    {instagram !== undefined && (
      <InstagramLink url={instagram} className={s['social-link']} />
    )}
    {twitter !== undefined && (
      <TwitterLink url={twitter} className={s['social-link']} />
    )}
  </div>
)

export const SocialLink = ({ Svg, url, ...rest }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    className={s['social-link-wrapper']}
  >
    <Svg {...rest} />
  </a>
)

export const FacebookLink = props => <SocialLink Svg={Facebook} {...props} />

export const InstagramLink = props => <SocialLink Svg={Instagram} {...props} />

export const TwitterLink = props => <SocialLink Svg={Twitter} {...props} />
