export const appConfig = {
  sanity: {
    projectId: '1opf3ch9',
    dataset: 'landing'
  },
  gaTrackingId: process.env.GA_TRACKING_ID,
  social: {
    facebook: 'https://www.facebook.com/RadicalHumility/',
    instagram: 'https://www.instagram.com/radicalhumilitybook/',
    twitter: 'https://twitter.com/radhumility'
  },
  email: 'radicalhumility@umich.edu',
  mailchimp: {
    url:'https://ageofhumility.us7.list-manage.com/subscribe/post',
    u: '33edc764a976a123d54fb6ea8',
    id: 'c23aa3a8e2'
  }
}
