import React from 'react'
import { byLastName } from 'utils/format'

// Components & Modules
import { withCmsData } from 'modules/cms-data'
import { GAPage } from 'components/page'
import { ContributorList } from 'components/contributor-list'
import { PageContainer } from 'components/page-structure'
import { Footer } from 'components/footer'
import { Banner } from 'components/banner'

// Style
import s from './index.module.scss'

export const Contributors = withCmsData(({ cms }) => {
  return (
    <GAPage key="contributors">
      <Banner navLinks={cms.getNavigation('navbar')} />
      <PageContainer>
        <div className={s['container']}>
          <div className={s['display']}>
            <RoleSection {...cms.getRole('editor')} key="editors" />
            <RoleSection {...cms.getRole('author')} key="author" />
            {/* {cms.roles
              .filter(({ title }) => (
                !['editor', 'author', 'designer'].includes(title)
              ))
              .map(role => <RoleSection {...role} key={role.title} />)
            } */}
            <RoleSection {...cms.getRole('illustrator')} key="illustrator" />
            <RoleSection {...cms.getRole('designer')} key="designer" />
          </div>
        </div>
      </PageContainer>
      <Footer navLinks={cms.getNavigation('footer')} />
    </GAPage>
  )
})

export const RoleSection = ({ title = '', contributors = [] }) => (
  <section className={s['role-container']}>
    <h3 className={s['role-title']}>{title}s</h3>
    <div className={s['role-display']}>
      <ContributorList contributors={contributors.sort(byLastName)} />
    </div>
  </section>
)
