import React from 'react'
import { injectWrapper } from 'modules/inject'
import { matchPath } from 'react-router-dom'


const defaultData = {
  contributors: [],
  roles: []
}

const { Provider, Consumer } = React.createContext({
  cms: { ...defaultData }
})

export class CmsDataStore extends React.Component {
  constructor() {
    super()

    // Bind functions
    this.methods = {
      getContributor: this.getData.bind(this, 'contributors', 'slug'),
      getContributors: this.getListedData.bind(this, 'contributors', 'slug'),
      getStory: this.getData.bind(this, 'stories', 'slug'),
      getStories: this.getListedData.bind(this, 'stories', 'slug'),
      getRole: this.getData.bind(this, 'roles', 'title'),
      getPage: this.getPage.bind(this),
      getNavigation: titleName =>
        this.getData('navigation', 'title', titleName).pages || []
    }
  }

  componentDidMount() {}

  getPage(url) {
    const { pages } = this.props
    return pages.find(({ slug }) => {
      const match = matchPath(url, {
        path: slug.startsWith('/') ? slug : `/${slug}`,
        exact: true,
        strict: false
      })

      return match !== null
    })
  }

  getData(datasetName, fieldName, value) {
    const dataset = this.props[datasetName]
    return dataset.find(item => item[fieldName] === value)
  }

  getListedData(datasetName, fieldName, values = []) {
    const dataset = this.props[datasetName]
    return dataset.filter(({ [fieldName]: itemVal }) =>
      values.includes(itemVal)
    )
  }

  render() {
    const { onLoad, children, ...datasets } = this.props

    const providerData = {
      cms: {
        ...datasets,
        ...this.methods,
        ...this.routes
      }
    }

    return (
      <Provider value={providerData}>
        {typeof children === 'function' ? children(providerData) : children}
      </Provider>
    )
  }
}

export const withCmsData = injectWrapper.bind(null, Consumer)

export { imageUrl } from './utils'
