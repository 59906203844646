import qs from 'qs'
import axios from 'axios'
import { inDevelopment } from 'utils/env-utils'

//Used to subscribe
export const subscribeEmail = formData => {
  return axios.post(
    'https://formcarry.com/s/mLDJZqqSCji',
    qs.stringify(formData)
  )
    .catch(e => {
      if (inDevelopment()) {
        console.error(e)
      }
      throw new Error(e)
    })
}

export const postForm = formData =>
  axios.post('https://formcarry.com/s/RcgdXdpVOU9', qs.stringify(formData))

export const camelToSnake = text => {
  return text.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase())
}
