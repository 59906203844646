import React from 'react'
import { ScrollContext } from './scroll-context'

class ScrollSubcontext extends React.Component {
  constructor() {
    super()

    this.subcontextElement = React.createRef()
  }

  render() {
    const { children, scrollPosition } = this.props

    // if the frame scroll frame is still undefined
    if (this.subcontextElement === undefined || scrollPosition === undefined) {
      return children({
        subcontextElement: this.subcontextElement
      })
    }

    const {
      clientHeight: subcontextHeight,
      clientWidth: subcontextWidth
    } = this.subcontextElement.current

    const { top, left } = this.subcontextElement.current.getBoundingClientRect()

    const subcontextTop = top - scrollPosition.clientTop
    const subcontextLeft = left - scrollPosition.clientLeft

    const frameHeight = scrollPosition.clientHeight
    const frameWidth = scrollPosition.clientWidth

    return children({
      subcontextElement: this.subcontextElement,
      subcontextPosition: {
        frameHeight,
        frameWidth,
        top: subcontextTop,
        left: subcontextLeft,
        height: subcontextHeight,
        width: subcontextWidth
      }
    })
  }
}

function ScrollSubontextWrapper(props) {
  return (
    <ScrollContext.Consumer>
      {({ scrollPosition }) => (
        <ScrollSubcontext {...props} scrollPosition={scrollPosition} />
      )}
    </ScrollContext.Consumer>
  )
}

export { ScrollSubontextWrapper as ScrollSubcontext }
