import React from 'react'
import { byLastName } from 'utils/format'

// Components & Modules
import { withCmsData } from 'modules/cms-data'
import { GAPage } from 'components/page'
import { Footer } from 'components/footer'
import { PageContainer } from 'components/page-structure'
import { VideoBlock } from 'components/sanity'
import { Link } from 'react-router-dom'
import { Banner } from 'components/banner'

// react hash link
import { HashLink } from 'react-router-hash-link';

// Style
import s from './index.module.scss'

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateString).toLocaleDateString(undefined, options)
}

export const Stories = withCmsData(({ cms, content = [] }) => {


  return (
    <GAPage key="your-stories">
      <Banner navLinks={cms.getNavigation('navbar')} />
      <PageContainer>
        <div className={s['container']}>
          <div className="base-content">
            <VideoBlock blocks={content} />
          
            <StoryList stories={cms.stories}/>
          </div>
          <div className={s['contributor-container']}>
            <div className={s['contributor-display']}>
              <h3 className={s['contributor-title-stories']}>contents</h3> 

              <TOCList stories={cms.stories}/>
              
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer navLinks={cms.getNavigation('footer')} />
    </GAPage>
  )
})


export const StoryList = ({ stories }) => (
  <div className={s['story-list']}>
    {stories.map(({ title, author, essay, banner, slug, publishDate }, idx) => {
      const {fName, lName, image, slug: authorSlug } = author
      return (
        <div className={s['story-wrap']} key={idx} >
          <div className={s['story-content']} >
            <div className={s['story-banner']} id={`${slug}`}> 
            { banner ? (
              <img className={s['story-banner-image']} src={banner}/>

             ) : ( 
              <div className={s['banner-bumper']}></div>
            ) }
            </div>
            <div className={s['story-body']}> 
              <Link to={`/your-stories/${slug}`} className={s['story-title-and-date']}>
                <div className={s['story-title']}>
                  {title}
                </div>
                <div className={s['story-date']}>
                  {formatDate(publishDate)}
                </div>
              </Link>

              <div className={s['byline']}>
                { image ? ( 
                  <>
                  <Link to={`/contributors/${authorSlug}`} className={s['story-author']}>
                      <img className={s['story-profile-image']} src={image}/>
                  </Link>
                  <Link to={`/contributors/${authorSlug}`} className={s['story-author']}>
                    {`${fName} ${lName}`}
                  </Link>
                  </>
                ) : ( 
                  <div  className={s['story-author']}>
                    {`${fName} ${lName}`}
                  </div>

                ) }

              </div>
              <div className={s['story-essay']}>
                <VideoBlock blocks={essay}/>
              </div>
            </div>
          </div>
        </div>
      )}
    )}
  </div>
)

export const TOCList = ({ stories }) => {

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }
  
  return (
    <div className={s['toc-story-list-wrap']}>
      <div className={s['toc-story-list']}>
        {stories.map(({ title, author, slug }, idx) => {
          const {fName, lName, slug: authorSlug} = author
          return (
            <div className={s['toc-story']} key={idx}>
              <HashLink 
                smooth
                to={`#${slug}`} className={s['role-contributor']}>
                <div className={s['toc-story-title']}>
                  {title} 
                </div>
                <div className={s['toc-story-author']}>
                  {`${fName} ${lName}`}
                </div>
              </HashLink>
            </div>
          )}
        )}
        <div className={s['toc-end-of-list-bumper']}></div>
      </div>
      <div className={s['toc-story-list-mask']}>

      </div>

    </div>

  )
}
