import React from 'react'

// Components & Modules
import { withCmsData } from 'modules/cms-data'
import { GAPage } from 'components/page'
import { Footer } from 'components/footer'
import { PageContainer } from 'components/page-structure'
import { Link } from 'react-router-dom'
import { Banner } from 'components/banner'
import { ScrollTracker } from 'modules/scroll-tracker'

// Style
import s from './index.module.scss'

// Assets

import LandingImage01 from 'assets/flat-landing-01-radical.png'
import LandingImage02 from 'assets/flat-landing-02.png'
import LandingImage03 from 'assets/flat-landing-03.png'
import LandingImage04 from 'assets/flat-landing-04.png'
import LandingImage05 from 'assets/flat-landing-05.png'
import LandingImage06 from 'assets/flat-landing-06.png'
import LandingImage07 from 'assets/flat-landing-07.png'
import LandingImage08 from 'assets/flat-landing-08.png'
import LandingImage09 from 'assets/flat-landing-09.png'
import LandingImage10 from 'assets/flat-landing-10.png'

export const Home = withCmsData(({ cms }) => (
  <ScrollTracker>
    {({ trackScrollPosition, setScrollElement }) => {
      return (
        <GAPage
          key="home"
          onScroll={trackScrollPosition}
          ref={setScrollElement}
        >
          <Banner navLinks={cms.getNavigation('navbar')} />
          <PageContainer className={s['page-container']}>
            <div className={s['content-container']}>
              <img alt="" src={LandingImage01} className={s['landing-01']} />

              <Link to={`/contributors/valerie-tiberius`}>
                <img alt="" src={LandingImage02} className={s['landing-02']} />
              </Link>

              <img alt="" src={LandingImage03} className={s['landing-03']} />

              <Link to={`/contributors/rebekah-modrak`}>
                <img alt="" src={LandingImage04} className={s['landing-04']} />
              </Link>

              <img alt="" src={LandingImage05} className={s['landing-05']} />

              <Link to={`/contributors/charles-blow`}>
                <img alt="" src={LandingImage06} className={s['landing-06']} />
              </Link>

              <img alt="" src={LandingImage07} className={s['landing-07']} />

              <Link to={`/contributors/tyler-denmead`}>
                <img alt="" src={LandingImage08} className={s['landing-08']} />
              </Link>

              <img alt="" src={LandingImage09} className={s['landing-09']} />

              <Link to={`/contributors/kevin-hamilton`}>
                <img alt="" src={LandingImage10} className={s['landing-10']} />
              </Link>
            </div>
          </PageContainer>
          <Footer navLinks={cms.getNavigation('footer')} />
        </GAPage>
      )
    }}
  </ScrollTracker>
))
