import sanityClient from '@sanity/client'
import { appConfig } from 'app.config'
import { inDevelopment } from 'utils/env-utils'

const { projectId, dataset } = appConfig.sanity

export const sanity = sanityClient({
  projectId,
  dataset,
  token: '', // not needed currently
  useCdn: true
})

export const getData = () => {
  const navigationQuery = `*[_type=="navigation"]{
    title,
    "pages": pages[]->{ title, "url": slug.current }
  }`

  const pageQuery = `*[_type=="page"]{
    title,
    "content": coalesce(content, []),
    "slug": slug.current,
  }`

  const contributorQuery = `*[_type=="author"]{
    fName,
    lName,
    description,
    bio,
    "excerpt": coalesce(excerpt{
      body,
      "title": coalesce(title, ''),
    }, {
      "title": ''
    }),
    "slug": slug.current,
    "id": _id,
    "roles": coalesce(roles[]->title, []),
    "image": image.asset -> url,
    "related": coalesce(related[] -> slug.current, [])
  }`

  const storyAuthor = `author -> {
    fName,
    lName,
    "slug": slug.current,
    "image": image.asset -> url,
    "roles": coalesce(roles[]->title, [])
  }`

  const storyQuery = `*[_type=="your-stories"] | order(publishDate desc) {
    title,
    "author": ${storyAuthor},
    "slug": slug.current,
    publishDate,
    "banner": banner.asset -> url, 
    essay,
  }`

  const query = `{
    "contributors": ${contributorQuery},
    "pages": ${pageQuery},
    "navigation": ${navigationQuery},
    "stories": ${storyQuery}
  }`

  return sanity
    .fetch(query)
    .then(({ contributors, pages, navigation, stories, ...data }) => {
      const localContributors = sanitizeContributors(contributors)
      return {
        ...data,
        contributors: localContributors,
        pages: sanitizePages(pages),
        navigation: setupNavigation(navigation),
        roles: setupRoles(localContributors),
        stories: setupStories(stories)
      }
    })
    .then(data => {
      if (inDevelopment()) console.log(data)
      return data
    })
    .catch(e => {
      console.error(e)
      throw e
    })
}

export const sanitizeContributors = contributors =>
  contributors.map(contributor => ({
    ...contributor,
    get name() {
      return `${contributor.fName} ${contributor.lName}`
    }
  }))

export const sanitizePages = pages =>
  pages.map(({ slug, ...rest }) => ({
    ...rest,
    slug: slug.startsWith('/') ? slug : `/${slug}`
  }))

export const setupNavigation = navigationSet => {
  return navigationSet.map(({ pages, ...navigation }) => ({
    ...navigation,
    pages: pages.map(({ url, ...rest }) => ({
      ...rest,
      url: url.startsWith('/') ? url : `/${url}`
    }))
  }))
}

export const setupStories = stories => {
  if (!Boolean(stories)){
    return []
  } 
  return stories
  
}

export const setupRoles = contributors => {
  if (!contributors) return []
  return contributors.reduce((roleAccumulator, contributor) => {
    for (const role of contributor.roles) {
      const index = roleAccumulator.findIndex(({ title }) => title === role)
      if (index === -1) {
        roleAccumulator.push({ title: role, contributors: [contributor] })
      } else {
        roleAccumulator[index].contributors.push(contributor)
      }
    }

    return roleAccumulator
  }, [])
}
