import qs from 'qs'

export function shortDate(date) {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }
  return date.toLocaleString('en-US', dateOptions)
}

export function longDate(date) {
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  return isoToDate(date).toLocaleString('en-US', dateOptions)
}

export function isoToDate(isoDate) {
  const dateParts = isoDate.split('-')
  return new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
}

export function combineDate(start, end) {
  return `${longDate(start)} - ${longDate(end)}`
}

// Generated a query string from an object
export function queryString(obj) {
  // Remove keys with an empty value pair
  const formattedObj = Object.keys(obj).reduce((nonempty, key) => {
    if (obj[key]) {
      return { ...nonempty, [key]: obj[key] }
    }
    return { ...nonempty }
  }, {})
  return qs.stringify(formattedObj)
}

export function url(base, queryParams = {}) {
  const query = queryString(queryParams)
  const querySuffix = query.length > 0 ? `?${query}` : ''
  return `${base}${querySuffix}`
}

export function objectMap(obj, callback) {
  return Object.keys(obj).reduce(
    (mapped, key, index, array) => ({
      ...mapped,
      ...callback(key, obj[key], index, obj)
    }),
    {}
  )
}

export const byLastName = ({ lName: aName }, { lName: bName }) => {
  // aName and bName last names
  if (aName === bName) return 0
  return aName < bName ? -1 : 1
}
