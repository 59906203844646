import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const propTypes = {
  staticContext: PropTypes.any
}

class OnMountConnectedComponent extends React.Component {
  componentDidMount() {
    const { match, callback } = this.props
    callback(match.url)
  }

  render() {
    const { children } = this.props
    return children
  }
}

OnMountConnectedComponent.propTypes = propTypes

export const AnalyticsConnectedComponent = withRouter(OnMountConnectedComponent)
