import React from 'react'
import ReactDOM from 'react-dom'
import { getData } from 'utils/sanity'
import { getContainerId } from 'utils/env-utils'

// IMPORT FIRST to enable cascading overrides by other components
import s from 'style/index.module.scss'

// Components & Modules
import { CmsDataStore } from 'modules/cms-data'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { UserManager } from 'modules/user'

// Sections
import { About } from 'sections/about'
import { Stories } from 'sections/stories'
import { SingleStory } from 'sections/single-story'
import { Base } from 'sections/base'
import { Home } from 'sections/home'
import { Contributors } from 'sections/contributors'
import { ContributorBio } from 'sections/contributor-bio'
import { Share } from 'sections/share'

export const App = data => (
  <CmsDataStore {...data}>
    {({ cms }) => (
      <UserManager>
        <div className={s['app']}>
          <Router>
            <React.Fragment>
              <Switch>
                <Route path="/share" exact>
                  {({ location }) => (
                    <Share {...cms.getPage(location.pathname)} />
                  )}
                </Route>
                <Route path="/about" exact>
                  {({ location }) => (
                    <About {...cms.getPage(location.pathname)} />
                  )}
                </Route>
                <Route path="/your-stories" exact>
                  {({ location }) => (
                    <Stories {...cms.getPage(location.pathname)} />
                  )}
                </Route>
                <Route path="/your-stories/:slug">
                  {({ match }) => {
                    const story = cms.getStory(match.params.slug)
                    console.log("wait this worked?", story)
                    return story === undefined ? (
                      <Redirect to="/your-stories" />
                    ) : (
                      <SingleStory story={story}/>
                    )
                  }}
                </Route>
                <Route path="/contributors" exact component={Contributors} />
                <Route path="/contributors/:slug">
                  {({ match }) => {
                    const contributor = cms.getContributor(match.params.slug)
                    return contributor === undefined ? (
                      <Redirect to="/contributors" />
                    ) : (
                      <ContributorBio contributor={contributor} />
                    )
                  }}
                </Route>
                <Route path="/" exact component={Home} />
                <Route path="/">
                  {({ location }) => {
                    const page = cms.getPage(location.pathname)
                    if (page === undefined) return <Redirect to="/" />
                    return <Base {...page} />
                  }}
                </Route>
              </Switch>
            </React.Fragment>
          </Router>
        </div>
      </UserManager>
    )}
  </CmsDataStore>
)

window.onload = () => {
  getData()
    .then(data => {
      ReactDOM.render(
        <App {...data} />,
        document.getElementById(getContainerId())
      )
    })
    .catch(e => {
      console.trace(e)
    })
}
