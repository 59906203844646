import React from 'react'
import { appConfig } from 'app.config'

// Components
import { Link } from 'react-router-dom'
import { ReactComponent as Border } from 'assets/border.svg'
import { Burger } from './burger'
import { Logo } from 'components/logo'
import { SocialList } from 'components/social-list'

// Style
import s from './index.module.scss'

export class Banner extends React.Component {
  constructor() {
    super()

    this.state = {
      open: false
    }
    // Bind Functions
    this.setOpen = this.setOpen.bind(this)

    window.menu = this
  }

  setOpen(isOpen) {
    this.setState({
      open: isOpen
    })
  }

  render() {
    const { mainLink = { title: 'Home', url: '/' }, navLinks = [] } = this.props

    const { open } = this.state

    return (
      <header className={s['container']}>
        <div className={s['mobile-display']}>
          <div className={s['logo-container']}>
            <Link to={mainLink.url} onClick={() => this.setOpen(false)}>
              <Logo className={s['mobile-logo']} />
            </Link>
          </div>
          <div className={s['menu-switch-container']}>
            <Burger flip={open} onClick={() => this.setOpen(!open)} />
          </div>
        </div>
        <nav className={`${s['nav-display']} ${open ? s['active'] : ''}`}>
          <div>
            <div className={s['nav-logo-display']}>
              <Link to={mainLink.url} onClick={() => this.setOpen(false)}>
                <Logo className={s['links-logo']} />
              </Link>
            </div>
            <div className={s['nav-details']}>
              <div className={s['nav-links-display']}>
                {navLinks.map(({ title, url }, idx) => (
                  <Link
                    to={url}
                    className={s['link-item']}
                    key={idx}
                    onClick={() => this.setOpen(false)}
                  >
                    {title}
                  </Link>
                ))}
              </div>
              <div className={s['nav-social-container']}>
                <Border
                  preserveAspectRatio="xMaxYMax slice"
                  className={s['nav-social-border']}
                />
                <div className={s['nav-social-display']}>
                  <span className={`${s['social-title']}`}>contact us</span>
                  <SocialList
                    className={s['social-list']}
                    {...appConfig.social}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className={s['border']}>
          <Border preserveAspectRatio="xMidYMax slice" />
        </div>
      </header>
    )
  }
}
