import React from 'react'
import PropTypes from 'prop-types'

// Utilities

// Components
import { ReactComponent as MenuBarOne } from 'assets/humility-menu-bar-3.svg'
import { ReactComponent as MenuBarTwo } from 'assets/humility-menu-bar-3.svg'

// Style
import s from './index.module.scss'

export const Burger = ({ isActive, flip = false, className = '', ...rest }) => (
  <div className={`${s['container']} ${className}`} {...rest}>
    <span className={`${s['bar']} ${flip ? s['flip'] : ''}`}>
      <MenuBarOne className={s['graphic']} />
    </span>
    <span className={`${s['bar']} ${flip ? s['flip'] : ''}`}>
      <MenuBarTwo className={s['graphic']} />
    </span>
  </div>
)

// Set Prop Types
Burger.propTypes = {
  className: PropTypes.string
}
