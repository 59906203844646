export const form = {
  name: { value: '' },
  email: { value: '' },
  screenName: { value: '' },
  occupation: { value: '' },
  location: { value: '' },
  race: { value: '' },
  gender: { value: '' },
  // publishPermission: { value: false },
  avatar: { value: '' },
  title: { value: '' },
  story: { value: '' }
}
