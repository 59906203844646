import React from 'react'
import { appConfig } from 'app.config'
import { withRouter } from 'react-router-dom'

// Components
import { Link } from 'react-router-dom'
import { InputText } from 'components/input-text'
import { MailchimpFormManager, STATUS } from 'modules/mailchimp-form'
import { SocialList } from 'components/social-list'

// Style
import s from './index.module.scss'

export const Footer = withRouter(({ history, navLinks = [] }) => {
  return (
    <footer className={s['container']}>
      <div className={s['display']}>
        <div className={s['contact']}>
          <EmailSection/>
          <div className={s['social-container']}>
            <span className={`${s['description']} ps`}>contact us</span>
            <div className={s['contact-methods']}>
              <p className={s['contact-email']}>
                you can email us at <br />
                <a href={`mailto:${appConfig.email}`}>
                  {appConfig.email}
                </a>
              </p>
              <SocialList {...appConfig.social} />
            </div>
          </div>
        </div>
        <div className={s['nav-links-container']}>
          <span className={`${s['description']} ps`}>pages</span>
          <div className={s['nav-links']}>
            {navLinks.map(({ title, url }, idx) => (
              <div className={s['link']} key={idx}>
                <Link to={url} className={s['link']}>
                  {title}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
})

function EmailSection() {
  return (
    <MailchimpFormManager>
      {(formData) => (
        <div className={s['email-section']}>
          <FormElement status={formData.status} data={formData} />
        </div>
      )}
    </MailchimpFormManager>
  )
}

function FormElement({ status, data}) {
  const { managedFields, managedSubmit } = data
  if (status === STATUS.READY) {
    return (
      <EmailForm
        managedFields={managedFields}
        managedSubmit={managedSubmit}
        key={status}
      />
    )
  } else if (status === STATUS.SUCCESS) {
    return (
      <SuccessMessage />
    )
  } else if (status === STATUS.ERROR) {
    return (
      <FailureMessage />
    )
  }
  return null
}

function SuccessMessage(props) {
  return (
    <div className={s['sign-up-message']} {...props}>
      <h3>Thank You</h3>
      <p>We're delighted to have you on our mailing list.</p>
    </div>
  )
}

function FailureMessage(props) {
  return (
    <div className={s['sign-up-message']} {...props}>
      <h3>We've run into an issue</h3>
      <p>
        If you email
        <a href={`mailto:${appConfig.email}`}>{appConfig.email}</a>
        we'll add you to our mailing list.
      </p>
    </div>
  )
}

function EmailForm({ managedFields, managedSubmit, status, ...rest }) {
  return (
    <form
      {...rest}
      className={s['sign-up']}
      onSubmit={managedSubmit}
    >
      <label className={`${s['description']} ps`}>
        sign up for updates
      </label>
      <div className={s['inputs']}>
        <InputText
          className={s['email']}
          fieldId="email"
          fields={managedFields}
          type="email"
          placeholder="email address"
          required
        />
        <button type="submit" className={s['submit']}>
          Join Us
        </button>
      </div>
    </form>
  )
}
