import React from 'react'
import { imageUrl } from 'modules/cms-data'

// Components and Modules
import { withCmsData } from 'modules/cms-data'
import { GAPage } from 'components/page'
import { Footer } from 'components/footer'
import { PageContainer } from 'components/page-structure'
import { ContributorList } from 'components/contributor-list'
import { VideoBlock } from 'components/sanity'
import { Banner } from 'components/banner'

// Style
import s from './index.module.scss'

export const ContributorBio = withCmsData(
  ({ contributor: { image, name, slug, bio, excerpt, related }, cms }) => {
    const profilePic = imageUrl(image, { width: 400 })
    const relatedContributors = cms.getContributors(related)
    const hasRelated =
      relatedContributors !== undefined && relatedContributors.length > 0
    return (
      <GAPage key={slug}>
        <Banner navLinks={cms.getNavigation('navbar')} />
        <PageContainer>
          <div className={s['wrapper']}>
            <div className={s['portrait-container']}>
              <img
                className={s['portrait']}
                src={profilePic}
                alt={`Watercolor of ${name}'s face'`}
              />
            </div>

            <div className={s['content-container']}>
              <div className={s['title-container']}>
                <h1 className={s['title']}>{name}</h1>
                {excerpt.title.length > 0 && (
                  <h2 className={s['subtitle']}>{excerpt.title}</h2>
                )}
              </div>
              <div className={s['body-container']}>
                {excerpt.body !== undefined && (
                  <div className={s['excerpt-container']}>
                    <VideoBlock blocks={excerpt.body} />
                  </div>
                )}
                {bio !== undefined && (
                  <React.Fragment>
                    {excerpt.body !== undefined && (
                      <p className={s['label']}>ABOUT THE AUTHOR</p>
                    )}
                    <div className={s['about-container']}>
                      <VideoBlock blocks={bio} />
                    </div>
                  </React.Fragment>
                )}
              </div>
              {hasRelated && (
                <div className={s['related']}>
                  <h2 className={s['related-title']}>Related Contributors</h2>
                  <ContributorList
                    contributors={cms.getContributors(related)}
                    noBreak
                  />
                </div>
              )}
            </div>
          </div>
        </PageContainer>
        <Footer navLinks={cms.getNavigation('footer')} />
      </GAPage>
    )
  }
)
