import React from 'react'

// Components
import { GAPage } from 'components/page'
import { Footer } from 'components/footer'
import { PageContainer } from 'components/page-structure'
import { withCmsData } from 'modules/cms-data'
import { VideoBlock } from 'components/sanity'
import { Banner } from 'components/banner'

// Style
import s from './index.module.scss'

// Sections
export const Base = withCmsData(
  ({ cms, content = [], title = '', url = '' }) => {
    return (
      <GAPage key={title}>
        <Banner navLinks={cms.getNavigation('navbar')} />
        <PageContainer className="base-content">
          <div className={s['display']}>
            <VideoBlock blocks={content} />
          </div>
        </PageContainer>
        <Footer navLinks={cms.getNavigation('footer')} />
      </GAPage>
    )
  }
)
