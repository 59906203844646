import React from 'react'

// Components
import { ReactComponent as Border } from 'assets/border.svg'

// Style
import s from './index.module.scss'

export const PageContainer = ({ className = '', ...rest }) => (
  <div className={s['container']}>
    <div className={`${s['display']} ${className}`} {...rest} />
    <div className={s['border-container']}>
      <Border preserveAspectRatio="xMidYMax slice" />
    </div>
  </div>
)

export const PageContentDisplay = ({ className = '', ...rest }) => (
  <div className={`${s['content-container']} ${className}`} {...rest} />
)
