import React from 'react'
import { byLastName } from 'utils/format'

// Components & Modules
import { withCmsData } from 'modules/cms-data'
import { GAPage } from 'components/page'
import { Footer } from 'components/footer'
import { PageContainer } from 'components/page-structure'
import { VideoBlock } from 'components/sanity'
import { Link } from 'react-router-dom'
import { Banner } from 'components/banner'
// react hash link
import { HashLink } from 'react-router-hash-link';

// Style
import s from './index.module.scss'

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(dateString).toLocaleDateString(undefined, options)
}

export const SingleStory = withCmsData(
  ({ story: { title, author, essay, banner, slug, publishDate }, cms }) => {
  const {fName, lName, image, slug: authorSlug, roles} = author
  console.log("author", author)
 

  return (
    <GAPage key={`your-stories/${slug}`}>
      <Banner navLinks={cms.getNavigation('navbar')} />
      <PageContainer>
        <div className={s['container']}>
          <div className="base-content">
          
            <div className={s['story-list']}>
              <div className={s['story-wrap']} >
                <div className={s['story-content']} >
                  <div className={s['story-banner']} id={`${slug}`}> 
                    { banner ? (
                      <img className={s['story-banner-image']} src={banner} alt="story banner"/>

                    ) : ( 
                      <div className={s['banner-bumper']}></div>
                    ) }
                  </div>
                  <div className={s['story-body']}> 
                    <div className={s['story-title']}>
                      {title}
                    </div>
                    <div className={s['story-date']}>
                      {formatDate(publishDate)}
                    </div>
                    <div className={s['byline']}>
                      { image ? ( 
                        <>
                        <Link to={`/contributors/${authorSlug}`} className={s['story-author']}>
                            <img className={s['story-profile-image']} src={image}/>
                        </Link>
                        <Link to={`/contributors/${authorSlug}`} className={s['story-author']}>
                          {`${fName} ${lName}`}
                        </Link>

                        </>
                      ) : ( 
                        <div  className={s['story-author']}>
                          {`${fName} ${lName}`}
                        </div>

                      ) }

                    </div>
                    <div className={s['story-essay']}>
                      <VideoBlock blocks={essay}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s['contributor-container']}>
            <div className={s['contributor-display']}>
              <h5 className={s['author-side-title']}>Story By</h5> 
                <div className={s['byline-side']}>
                  { image ? (

                    <Link 
                      to={`/contributors/${authorSlug}`}
                      className={s['story-author-side']}>
                      <img className={s['story-profile-image-side']} src={image}/>
                      {`${fName} ${lName}`}
                    </Link>

                    ) : (

                    <div  className={s['story-author']}>
                      {`${fName} ${lName}`}
                    </div>

                  )}
                  
                  <br/>
                  <Link to="/your-stories">See All Stories</Link>
                </div>
              
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer navLinks={cms.getNavigation('footer')} />
    </GAPage>
  )
})






