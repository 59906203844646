import React from 'react'

import { Field } from 'modules/form-manager'

// Style
import s from './index.module.scss'

export const InputText = ({
  fieldId,
  placeholder,
  className = '',
  required = false,
  ...rest
}) => (
  <div className={`${s['field']} ${className}`}>
    <Field
      fieldId={fieldId}
      filled={({ className, ...rest }) => ({
        className: `${className} ${s['filled']}`,
        ...rest
      })}
      className={s['field-input']}
      required={required}
      {...rest}
    />
    <label className={s['field-label']} htmlFor={fieldId}>
      {placeholder}
    </label>
    {!required && <span className={s['optional-tag']}>optional</span>}
  </div>
)
