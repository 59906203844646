import React from 'react'
import { Link } from 'react-router-dom'
import { appConfig } from 'app.config'
import qs from 'qs'

// Components
import BlockContent from '@sanity/block-content-to-react'
import Youtube from 'react-youtube'

// Style
import s from './index.module.scss'

export const VideoBlock = props => (
  <BlockContent
    {...appConfig.sanity}
    serializers={{
      types: {
        youtube: YoutubeVideo,
        button: LinkButton,
        block: BlockRenderer
      }
    }}
    {...props}
  />
)

const BlockRenderer = ({ node, ...props }) => {
  const style = node.style || 'normal'

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    return <h2 className={`my-heading level-${level}`}>{props.children}</h2>
  }
  switch (style) {
    case 'blockquote':
      return <blockquote>{props.children}</blockquote>
    case 'center':
      return <p className="center">{props.children}</p>
    default:
      return <p>{props.children}</p>
  }
}

export const ClassMark = props => {
  return <span>{props.children}</span>
}

export const NormalCenter = props => {
  return <p className="paragraph-center">{props.children}</p>
}

export const ShortBlock = ({ className = '', ...props }) => (
  <div className={`${className} ${s['short-block']}`}>
    <BlockContent
      projectId={appConfig.sanity.id}
      dataset={appConfig.sanity.dataset}
      serializers={{ types: { youtube: YoutubeVideo } }}
      {...props}
    />
  </div>
)

export const LinkButton = ({ node }) => {
  const { text = '', url = '/' } = node
  return (
    <div className="cms-button-container">
      <Link className="button h6 cms-button" to={url}>
        {text}
      </Link>
    </div>
  )
}

export const YoutubeVideo = ({ node }) => {
  const { videoId } = node
  const isUrl = videoId.search('youtube') > -1
  if (!isUrl) return <Youtube videoId={videoId} opts={youtubeOptions} />

  const params = videoId.slice(videoId.search(/\?/) + 1)
  const { v } = qs.parse(params)

  if (v === undefined) {
    console.warn(`Video ID malformed.`)
    console.warn(`Either provide a youtube link`)
    console.warn(`or the id for the safest results (the portion after the v=`)
    return null
  }

  return (
    <span className="youtube-video-container">
      <span className="youtube-video-display">
        <Youtube videoId={v} opts={youtubeOptions} className="youtube-video" />
      </span>
    </span>
  )
}

const youtubeOptions = {
  playerVars: {
    rel: 0
  }
}
