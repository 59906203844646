import React from 'react'
import { postForm, camelToSnake } from 'utils/form-carry'

// Components & Modules
import { GAPage } from 'components/page'
import { PageContainer } from 'components/page-structure'
import { Footer } from 'components/footer'
import {
  FormManager,
} from 'modules/form-manager'
import { withCmsData } from 'modules/cms-data'
import { form } from './form'
import TextareaAutosize from 'react-autosize-textarea'
import { VideoBlock } from 'components/sanity'
import { InputText } from 'components/input-text'
import { Banner } from 'components/banner'
import { STATUS } from './status'
import { Motion, spring } from 'react-motion'
import { StaggerList } from 'components/stagger-list'


import blueberry from 'assets/avatars/blueberry.png'
import carmel from 'assets/avatars/carmel.png'
import cottonCandy from 'assets/avatars/cotton-candy.png'
import gumdrop from 'assets/avatars/gumdrop.png'
import lime from 'assets/avatars/lime.png'
import lolipop from 'assets/avatars/lolipop.png'
import marshmellow from 'assets/avatars/marshmellow.png'
import marzipan from 'assets/avatars/marzipan.png'
import sherbert from 'assets/avatars/sherbert.png'
import toffee from 'assets/avatars/toffee.png'

// Style
import s from './index.module.scss'

const avatars = [
  { name: 'blueberry', src: blueberry },
  { name: 'carmel', src: carmel },
  { name: 'cottonCandy', src: cottonCandy },
  // { name: 'grapefruit', src: grapefruit },
  { name: 'gumdrop', src: gumdrop },
  // { name: 'lemon', src: lemon },
  { name: 'lime', src: lime },
  { name: 'lolipop', src: lolipop },
  { name: 'marshmellow', src: marshmellow },
  { name: 'marzipan', src: marzipan },
  { name: 'sherbert', src: sherbert },
  { name: 'toffee', src: toffee }
]

// Sections
class SharePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formStatus: STATUS.STORY
    }

    this.submitForm = this.submitForm.bind(this)
    this.setToStory = this.setFormStatus.bind(this, STATUS.STORY)
    this.setToAvatar = this.setFormStatus.bind(this, STATUS.AVATAR)
    this.setToAuthor = this.setFormStatus.bind(this, STATUS.AUTHOR)
    this.selectAvatar = this.selectAvatar.bind(this)
  }

  setFormStatus(status) {
    this.setState({
      formStatus: status
    })
  }

  submitForm(fields, event) {
    const { name, ...detailFields } = fields
    const nameComponents = name.value.split(' ')
    const firstName = { value: nameComponents[0] }
    const lastName = { value: nameComponents.slice(1).join(' ') }
    const submissionFields = { firstName, lastName, ...detailFields }

    const keyVals = Object.keys(submissionFields).reduce(
      (fieldPairs, key) => ({
        ...fieldPairs,
        [camelToSnake(key)]: submissionFields[key].value
      }),
      {}
    )

    this.setState({
      formStatus: STATUS.SUCCESS,
    })

    postForm(keyVals)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            formStatus: STATUS.SUCCESS,
          })
          return response
        }
        else {
          this.setState({ formStatus: STATUS.FAILURE })
          return response
        }
      })
      .catch(e => {
        this.setState({ formStatus: STATUS.FAILURE })
      })
  }

  selectAvatar(managedFields, name) {
    managedFields.avatar.onChange(name)
    this.setToAuthor()
  }

  render() {
    const { cms, content = [] } = this.props
    const { formStatus } = this.state
    return (
      <GAPage key="Book">
        <Banner navLinks={cms.getNavigation('navbar')} />
        <PageContainer>
          <div className={s['description']}>
            <div className="base-content">
              {content.length > 0 && (
                <VideoBlock blocks={content} className={s['body']} />
              )}
            </div>
            <FormManager fields={form} submit={this.submitForm}>
              {managed => (
                <form onSubmit={managed.managedSubmit} className={s['form']} noValidate>
                  <FormWrapper active={formStatus}>
                    <StoryContent {...managed} next={this.setToAvatar}/>
                    <AvatarSection
                      {...managed}
                      previous={this.setToStory}
                      select={this.selectAvatar}
                    />
                    <AuthorDetails {...managed} previous={this.setToStory}/>
                    <Thanks/>
                  </FormWrapper>
                </form>
              )}
            </FormManager>
          </div>
        </PageContainer>
        <Footer navLinks={cms.getNavigation('footer')} />
      </GAPage>
    )
  }
}

const FormWrapper = ({ active, children }) => {
  const sectionOrder = [
    STATUS.STORY,
    STATUS.AVATAR,
    STATUS.AUTHOR,
    STATUS.SUCCESS
  ]

  const activeIndex = sectionOrder.findIndex(elt => elt === active)
  const translate = activeIndex * 150
  return (
    <div className={s['form-wrapper']}>
      <Motion defaultStyle={{x: 0}} style={{ x: spring(translate) }}>
        {({ x }) => {
          const transform = `translateX(-${x}%)`
          return (
            <div className={s['slider']} style={{ transform }}>
              {children}
            </div>
          )
        }}
      </Motion>
    </div>
  )
}

const StoryContent = ({ managedFields, managedSubmit, next }) => (
  <div className={s['form-section-wrapper']}>
    <fieldset className={s['form-section']}>
      <legend className={`${s['form-section-title']} `}>
        contact information
      </legend>
      <div className={s['contact-info']}>
        <InputText
          className={s['form-input']}
          fieldId="name"
          fields={managedFields}
          placeholder="full name"
          required
        />
        <InputText
          className={s['form-input']}
          fieldId="email"
          fields={managedFields}
          type="email"
          placeholder="email address"
          required
        />
      </div>
    </fieldset>
    <fieldset className={s['form-section']}>
      <legend className={`${s['form-section-title']} `}>your story</legend>
      <InputText
        fieldId="title"
        fields={managedFields}
        placeholder="story title"
        required
      />
      <TextareaAutosize
        className={s['story-field']}
        value={managedFields.story.value}
        name="specialRequests"
        id="specialRequests"
        required
        placeholder="it all started..."
        onChange={({ target }) => managedFields.story.onChange(target.value)}
      />
      <div className={s['submit-container']}>
        <button type='button' onClick={next} className={s['submit']}>
          select an avatar
        </button>
      </div>
    </fieldset>
  </div>
)

export const AvatarSection = ({ managedFields, managedSubmit, select }) => {
  const avatarElements = avatars.map(({ name, src }) => (
    <img
      alt=""
      src={src}
      onClick={select.bind(this, managedFields, name)}
      className={s['avatar-image']}
    />
  ))

  return (
    <div className={s['form-section-wrapper']}>
      <fieldset className={s['form-section']}>
        <legend className={`${s['form-section-title']} ${s['center']}`}>
          choose an author avatar
        </legend>
        <StaggerList elements={avatarElements}/>
      </fieldset>
    </div>
  )
}

export const AuthorDetails = ({ managedFields, managedSubmit, previous  }) => (
  <div className={s['form-section-wrapper']}>
    <span onClick={previous} className={s['back']}>{`< Edit Story`}</span>
    <fieldset className={s['form-section']}>
      <legend className={`${s['form-section-title']} `}>
        author details
      </legend>
      <div className={s['split']}>
        <InputText
          fieldId="screenName"
          fields={managedFields}
          placeholder="preferred screen name"
        />
        <InputText
          fieldId="occupation"
          fields={managedFields}
          placeholder="occupation"
        />
      </div>
      <div className={s['split']}>
        <InputText fieldId="race" fields={managedFields} placeholder="race" />
        <InputText
          fieldId="gender"
          fields={managedFields}
          placeholder="gender"
        />
      </div>
      <InputText
        fieldId="location"
        fields={managedFields}
        placeholder="where do you live?"
      />
    </fieldset>
    <div className={s['submit-container']}>
      <button type="submit" className={s['submit']}>
        submit your story
      </button>
    </div>
  </div>
)

export const Thanks = ({ managedFields, managedSubmit, previous  }) => (
  <div className={`${s['form-section-wrapper']} base-content`}>
    <h3 className={s['thanks-title']}>Thank You for Sharing Your Story</h3>
    <p className={s['thanks-text']}>
      We apreciate the diversity of perspectives on Humility we've gathered over the course of the project. We will email you before publishing your story to social media.  Follow us on Instagram, Facebook, and Twitter to see new stories, and follow our progress
    </p>
  </div>
)

export const Share = withCmsData(SharePage)
