import React from 'react'

export const injectWrapper = (
  Wrapper,
  Component,
  translator = inData => inData
) =>
  function InjectionWrappedComponent(props) {
    return (
      <Wrapper>
        {data => {
          const outData = translator(data)
          return <Component {...outData} {...props} />
        }}
      </Wrapper>
    )
  }
