import Cookies from 'universal-cookie'

const cookies = new Cookies()

function isExplicitlyTrue(environmentVariable) {
  return environmentVariable === "true"
}

export function isExplicitlyFalse (environmentVariable) {
  return environmentVariable === "false"
}

export function inDevelopment () {
  return isExplicitlyTrue(process.env.REACT_APP_DEVELOPMENT)
}

export function getContainerId () {
  return process.env.REACT_APP_CONTAINER_ID
}

export function getGaId () {
  return process.env.REACT_APP_GA_ID
}

export const createUserId = () => {
  return (
    `${Math.random()
      .toString(36)
      .slice(2)}_` +
    `${Math.random()
      .toString(36)
      .slice(2)}`
  )
}

export const getUserId = () => {
  return cookies.get('user_id')
}

export const setUserId = userId => {
  const twoYears = 63072000
  cookies.set('user_id', userId, { maxAge: twoYears })
}
