import React from 'react'

// Style
import styleSheet from './index.module.scss'

export { styleSheet }

export const AUTO = 'auto'

export const Imgry = ({
  style = {},
  height = AUTO,
  width = AUTO,
  s = styleSheet,
  className = '',
  src = '',
  alt = '',
  ...rest
}) => {
  const hasError = false
  const isAutoSized = height === AUTO || width === AUTO || hasError

  const placeholderStyle = isAutoSized
    ? {
        display: 'none'
      }
    : {
        paddingTop: `${(100 * height) / width}%`
      }

  const containerStyle = isAutoSized
    ? {
        ...style
      }
    : {
        position: 'relative',
        ...style
      }

  const imgStyle = isAutoSized
    ? {}
    : {
        position: 'absolute',
        top: 0,
        left: 0
      }

  return (
    <span
      className={`${s['container']} ${className}`}
      style={containerStyle}
      {...rest}
    >
      <span style={placeholderStyle} className={s['placeholder']} />
      <img className={s['image']} src={src} alt="" style={imgStyle} />
    </span>
  )
}
