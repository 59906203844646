import React from 'react'
import { FormManager } from 'modules/form-manager'
import STATUS from './status'
import { subscribeEmail } from 'utils/form-carry'

const defaultProps = {
  fields: {
    email: ''
  },
  submit: ({ email }) => subscribeEmail({ email: email.value })
}

export { STATUS }

export class MailchimpFormManager extends React.Component {
  constructor() {
    super()

    this.state = {
      status: STATUS.READY
    }

    this.submitForm = this.submitForm.bind(this)
  }

  submitForm(fields) {
      const { submit } = this.props
      this.setState({
        status: STATUS.SENDING,
      })
      return submit(fields)
        .then(response => {
          if (response.status === 200) {
            this.setState({
              status: STATUS.SUCCESS,
            })
            return response
          }
          else {
            this.setState({ status: STATUS.ERROR })
            return response
          }
        })
        .catch(e => {
          this.setState({ status: STATUS.ERROR })
        })
  }

  render() {
    const { status } = this.state

    // spread of props must come before submit field
    // otherwise it will be overriden
    return (
      <FormManager
        {...this.props}
        submit={this.submitForm}
        status={status}
      />
    )
  }
}

MailchimpFormManager.defaultProps = defaultProps
