import React from 'react'
import { byLastName } from 'utils/format'

// Components & Modules
import { withCmsData } from 'modules/cms-data'
import { GAPage } from 'components/page'
import { Footer } from 'components/footer'
import { PageContainer } from 'components/page-structure'
import { VideoBlock } from 'components/sanity'
import { Link } from 'react-router-dom'
import { Banner } from 'components/banner'

// Style
import s from './index.module.scss'

export const About = withCmsData(({ cms, content = [] }) => {
  const contributorRole = cms.getRole('author')
  const editorRole = cms.getRole('editor')
  const designerRole = cms.getRole('designer')
  const remainingRoles = cms.roles.filter(
    ({ title }) => !['author', 'editor', 'designer'].includes(title)
  )

  return (
    <GAPage key="home">
      <Banner navLinks={cms.getNavigation('navbar')} />
      <PageContainer>
        <div className={s['container']}>
          <div className="base-content">
            <VideoBlock blocks={content} />
          </div>
          <div className={s['contributor-container']}>
            <div className={s['contributor-display']}>
              <h3 className={s['contributor-title']}>contributors</h3>
              <RoleList {...editorRole} />
              <RoleList {...contributorRole} />
              {remainingRoles.map((role, idx) => (
                <RoleList {...role} key={idx} />
              ))}
              <RoleList {...designerRole} />
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer navLinks={cms.getNavigation('footer')} />
    </GAPage>
  )
})

export const RoleList = ({ title, contributors }) => (
  <div className={s['contributor-list']}>
    <h3 className={`p ${s['role-title']}`}>{title}s</h3>
    {contributors.sort(byLastName).map(({ name, slug }, idx) => (
      <React.Fragment key={idx}>
        <Link to={`/contributors/${slug}`} className={s['role-contributor']}>
          {name}
        </Link>
        <br />
      </React.Fragment>
    ))}
  </div>
)
