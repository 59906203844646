import React from 'react'
import ReactGA from 'react-ga'

import { getGaId } from "utils/env-utils"
import {
  getUserId,
  createUserId,
  setUserId,
  inDevelopment
} from 'utils/env-utils'
import { withLocationCallback } from './with-location-callback'

export const UserContext = React.createContext({
  user: undefined
})

export class UserManager extends React.Component {
  constructor() {
    super()

    // Make sure that the user has an ID before starting the app
    let userId = getUserId()
    if (!userId) {
      userId = createUserId()
      setUserId(userId)
    }

    ReactGA.initialize(getGaId(), {
      debug: inDevelopment(),
      gaOptions: {
        userId
      }
    })

    this.state = {
      id: userId
    }
  }

  render() {
    const { id } = this.state
    return <UserContext.Provider value={{ id }} {...this.props} />
  }
}

export const withUser = Component => {
  return function UserComponent(props) {
    return (
      <UserContext.Consumer>
        {user => <Component user={user} {...props} />}
      </UserContext.Consumer>
    )
  }
}

export const withPageView = withLocationCallback.bind(null, ReactGA.pageview)
export const withModalView = withLocationCallback.bind(null, ReactGA.modalView)
